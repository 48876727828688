import React, { useEffect, useState } from "react";
import { Container, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import styles from "./tablecomponent.module.css";
import { ReactComponent as SearchIcon } from "../../images/loupe.svg";
import ReactPaginate from "react-paginate";
import axiosConfig from "../../axiosConf.js";
import { useHistory } from "react-router-dom";

export const InputTypes = {
  Text: "Text",
  TextArea: "TextArea",
  Image: "Image",
  DropDown: "DropDown",
};

function TableComponent(props) {
  const [dataArray, setDataArray] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIDs] = useState([]);
  const [mainCheckBoxOn, setMainCheckBoxOn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (dataArray) {
      setMainCheckBox();
    }
  }, [selectedIds, dataArray]);

  useEffect(() => {
    getData(1, "");
  }, [props.params, props.onUpdate]);

  function getData(page, search) {
    try {
      axiosConfig
        .get(
          props.getEP +
            "?page=" +
            page +
            "&search=" +
            (search ?? "") +
            (props.params ?? "")
        )
        .then((response) => {
          console.log(response.data);
          setDataArray(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  }

  function deleteData() {
    try {
      axiosConfig
        .post(props.deleteEP, { selectedIds })
        .then((response) => {
          getData(1, "");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  }

  function mainCheckBoxClicked(value) {
    console.log(value);
    var newArray = [...selectedIds];
    if (value === true) {
      // loop data, if not already in selected then add
      for (var i = 0; i < dataArray.data.length; i++) {
        if (!newArray.includes(dataArray.data[i].id)) {
          newArray = [...newArray, dataArray.data[i].id];
        }
      }
      setSelectedIDs(newArray);
      setMainCheckBoxOn(true);
    } else {
      for (var i = 0; i < dataArray.data.length; i++) {
        if (newArray.includes(dataArray.data[i].id)) {
          for (var j = 0; j < newArray.length; j++) {
            if (newArray[j] === dataArray.data[i].id) {
              newArray.splice(j, 1);
              j--;
            }
          }
        }
      }
      setMainCheckBoxOn(false);
      setSelectedIDs(newArray);
    }
  }

  const checkBoxClicked = (value, id) => {
    var newArray = [...selectedIds];
    if (selectedIds.includes(id)) {
      for (var i = 0; i < newArray.length; i++) {
        if (newArray[i] === id) {
          newArray.splice(i, 1);
          i--;
        }
      }
    } else {
      newArray = [...newArray, id];
    }
    setSelectedIDs(newArray);
  };

  const setMainCheckBox = () => {
    var selectedArray = [...selectedIds];
    var count = 0;
    for (var i = 0; i < dataArray.data.length; i++) {
      if (selectedArray.includes(dataArray.data[i].id)) {
        count += 1;
      }
    }
    if (count === dataArray.data.length) {
      setMainCheckBoxOn(true);
    } else {
      setMainCheckBoxOn(false);
    }
  };
  function customImage(value, item) {
    if (item.custom_component === undefined || value === "") {
      return value;
    } else {
      const SpecificStory = props.components[item.custom_component];
      return <SpecificStory data={value} />;
    }
  }

  const rowClicked = (item) => {
    console.log(item);
    if (props.canSelectRow) {
      var array = { id: item.id, row: [] };
      props.headers.map((item2, i) => {
        array["row"][array["row"].length] = {
          title: item2.title,
          place_holder: item2.place_holder,
          required: true,
          current_value: item[item2.value],
          modal_component: item2.modal_component,
          disabled: item2.disabled,
        };
      });
      props.showModal(array);
      console.log(array);
    }
  };

  return (
    <Container className={styles["table-container"]} fluid>
      {loading === false && (
        <Container className={"table-container-inner"} fluid>
          <Row className={styles["table-functions"]}>
            <Col>
              <SearchIcon />
              <input
                onKeyUp={(e) => getData(1, e.target.value)}
                type="text"
                placeholder="Search"
              />
            </Col>
            {props.deleteEP && (
              <Col>
                <DropdownButton title="Bulk Action">
                  <Dropdown.Item onClick={() => deleteData()}>
                    Delete
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
            )}
          </Row>
          <Row className={styles["table"]}>
            <table>
              <th>
                <div>
                  <input
                    onClick={(e) => mainCheckBoxClicked(e.target.checked)}
                    checked={mainCheckBoxOn}
                    type="checkbox"
                    className={styles["checkbox"]}
                    name="ordersSelect"
                    id="ordersSelectOne"
                  />
                </div>
              </th>{" "}
              {props.headers.map((item, i) => {
                return <th>{item.title}</th>;
              })}
              {dataArray &&
                dataArray.data?.map((item, i) => {
                  return (
                    <tr onClick={() => rowClicked(item)}>
                      <td>
                        <div>
                          <input
                            key={item.id}
                            onClick={(e) =>
                              e.stopPropagation() +
                              checkBoxClicked(e.target.value, item.id)
                            }
                            checked={
                              selectedIds.includes(item.id) ? true : false
                            }
                            type="checkbox"
                            className={styles["checkbox"]}
                            name="ordersSelect"
                            id="ordersSelectOne"
                          />
                        </div>
                      </td>
                      {props.headers.map((item2, i) => {
                        return (
                          <td
                            style={{
                              maxWidth: "150px",
                              whiteSpace: "pre-wrap",
                              padding: "10px",
                            }}
                          >
                            {customImage(item[item2.value], item2)}
                          </td>
                        );
                      })}{" "}
                    </tr>
                  );
                })}
            </table>
          </Row>
          {loading === false && dataArray.total_pages > 1 && (
            <Row key={selectedIds} className={styles["pagination-row"]}>
              <div id="react-paginate">
                <ReactPaginate
                  onPageChange={(e) =>
                    setCurrentPage(e.selected + 1) + getData(e.selected + 1, "")
                  }
                  initialPage={currentPage - 1}
                  currentPage={currentPage}
                  pageCount={dataArray.total_pages}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={2}
                />
              </div>
            </Row>
          )}
        </Container>
      )}

      {loading === true && (
        <Row className={styles["loading-row"]}>
          <lottie-player
            src={"./Lottie/loading.json"}
            background="transparent"
            style={{ height: "150px", width: "200px" }}
            speed="1"
            loop
            autoplay
          />
        </Row>
      )}
    </Container>
  );
}
export default TableComponent;

import React, { useEffect, useState, Component } from "react";
import { Modal, Button, Container, Row } from "react-bootstrap";
import styles from "./genericmodal.module.css";
import axiosConfig from "../../axiosConf.js";

function GenericModal(props) {
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(props.data);
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  function submitButton(deletePressed) {
    var i;
    for (i = 0; i < data.length; i++) {
      console.log(data[i].value);
      if (
        (data[i].value === undefined || data[i].value === "") &&
        data[i].required === true
      ) {
        setError(data[i].title + " cannot be empty.");
        return;
      }
    }

    try {
      var ep = "";
      if (props.data.id !== undefined) {
        ep = props.editEP;
      } else {
        ep = props.addEP;
      }

      console.log(data);
      var dataTemp = { data: {}, post_data: "", id: "" };
      dataTemp["data"] = [...data.row];
      if (props.postData) {
        dataTemp["post_data"] = props.postData.day ?? "";
      }
      dataTemp["id"] = data.id ?? "";
      console.log(dataTemp);

      if (deletePressed) {
        ep = props.deleteEP;
        dataTemp = { selectedIds: [data.id] };
      }

      axiosConfig
        .post(ep, dataTemp)
        .then((response) => {
          console.log(response.status);
          props.reload();
          props.onHide();
        })
        .catch((error) => {
          console.log(error);
          setError(error.response.data);
        });
    } catch (error) {
      console.log(error);
      setError(error.response);
    }
  }

  function updateInputValue(e) {
    console.log(e);
    setError();
    var dataTemp = JSON.parse(JSON.stringify(data));
    console.log(dataTemp);
    dataTemp["row"][e.index]["current_value"] = e.value;
    setData(dataTemp);
  }

  function returnInput(item, index) {
    console.log(item);
    if (item.modal_component === undefined) {
      return item.current_value;
    } else {
      const SpecificStory = props.components[item.modal_component];
      return (
        <SpecificStory
          value_changed={(e) => updateInputValue(e)}
          setLoading={(e) => setLoading(e)}
          data={item}
          index={index}
        />
      );
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data.id ? "Edit" : "Add"} 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {data &&
            data.row &&
            data.row.map((item, i) => (
              <div>
                <Row className={styles["input-text"]}>{item.title}</Row>
                <Row>{returnInput(item, i)}</Row>
              </div>
            ))}

          <Row className={styles["error-row"]}>{error}</Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {props.deleteEP && data.id && (
          <Button
            style={{ backgroundColor: "red", outline: "none", border: "none" }}
            onClick={() => submitButton(true)}
          >
            Delete
          </Button>
        )}
        {loading == false ? (
          <Button onClick={() => submitButton()}>
            {props.show && data.id ? "Save" : "Add"}
          </Button>
        ) : (
          <Button disabled onClick={() => submitButton()}>
            Wait
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
export default GenericModal;

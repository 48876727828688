import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import TextArea from "../Components/Tables/CustomComponents/TextAreaInput";
import DayDropDown from "../Components/Tables/CustomComponents/DayDropDown";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";

const components = {
  DayDropDown: DayDropDown,
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  TextArea: TextArea,
};

function Mixes() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Main Title",
      value: "main_title",
      editable: true,
      place_holder: "DJ Connor Jay",
      modal_component: "Text",
    },
    {
      title: "Title",
      value: "title",
      editable: true,
      place_holder: "The after party mix",
      modal_component: "Text",
    },
    {
      title: "Subtitle",
      value: "subtitle",
      editable: true,
      place_holder: "Break the house down",
      modal_component: "Text",
    },
    {
      title: "Track ID",
      value: "track_id",
      editable: true,
      place_holder: "Track ID from SoundCloud embed code",
      modal_component: "Text",
    },
    {
      title: "Share Link",
      value: "share",
      editable: true,
      place_holder: "https://www.barfibre.com/",
      modal_component: "Text",
    },
    {
      title: "Instagram User",
      value: "instagram",
      editable: true,
      place_holder: "ConnorJay",
      modal_component: "Text",
    },
    {
      title: "Aspect Ratio",
      value: "aspect_ratio",
      editable: true,
      place_holder: "0.5",
      modal_component: "Text",
    },
  ];
  const modalData = {
    id: undefined,
    row: [
      {
        title: "Main Title",
        value: "main_title",
        editable: true,
        place_holder: "DJ Connor Jay",
        modal_component: "Text",
      },
      {
        title: "Title",
        value: "title",
        editable: true,
        place_holder: "The after party mix",
        modal_component: "Text",
      },
      {
        title: "Subtitle",
        value: "subtitle",
        editable: true,
        place_holder: "Break the house down",
        modal_component: "Text",
      },
      {
        title: "Track ID",
        value: "track_id",
        editable: true,
        place_holder: "Track ID from SoundCloud embed code",
        modal_component: "Text",
      },
      {
        title: "Share Link",
        value: "share",
        editable: true,
        place_holder: "https://www.barfibre.com/",
        modal_component: "Text",
      },
      {
        title: "Instagram User",
        value: "instagram",
        editable: true,
        place_holder: "ConnorJay",
        modal_component: "Text",
      },
      {
        title: "Aspect Ratio",
        value: "aspect_ratio",
        editable: true,
        place_holder: "0.5",
        modal_component: "Text",
      },
    ],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"Mixes"}
            buttonText={"Add mix"}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={"/deletemix.php"}
            getEP={"/mixes.php"}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => setUpdateData(updateData + 1)}
          addEP={"/addmix.php"}
          deleteEP={"/deletemix.php"}
          editEP={"/updatemix.php"}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default Mixes;

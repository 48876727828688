import React, { useState, useEffect } from "react";
import { Container, Row, Accordion, Card } from "react-bootstrap";
import { ReactComponent as CalendarIcon } from "../images/calendar.svg";
import { ReactComponent as DrinkIcon } from "../images/water-glass.svg";
import { ReactComponent as UserIcon } from "../images/user.svg";
import { ReactComponent as SettingsIcon } from "../images/settings.svg";
import { ReactComponent as ArrowIcon } from "../images/right-arrow.svg";
import { ReactComponent as BellIcon } from "../images/bell.svg";
import { ReactComponent as GiftIcon } from "../images/gift-box.svg";
import { ReactComponent as BannerIcon } from "../images/banner.svg";
import { ReactComponent as PencilIcon } from "../images/pencil.svg";
import { useHistory } from "react-router-dom";
import styles from "./sidemenu.module.css";

function Menu(props) {
  const [mouseOver, setMouseOver] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [openTab, setOpenTab] = useState(0);
  const [selectedChild, setSelectedChild] = useState(1);
  const [mouseOverChild, setMouseOverChild] = useState();
  let history = useHistory();

  const menuArray = [
    {
      TabName: "Customers",
      TabIcon: "UserIcon",
      children: [{ title: "View Customers", routerLink: "/customers" }],
    },
    {
      TabName: "Shake & Win",
      TabIcon: "GiftIcon",
      children: [
        { title: "Prizes", routerLink: "/prizes" },
        { title: "Shake Log", routerLink: "/shakelog" },
      ],
    },

    {
      TabName: "Notifications",
      TabIcon: "BellIcon",
      children: [{ title: "Create Notification", routerLink: "/notification" }],
    },
    {
      TabName: "Content",
      TabIcon: "PencilIcon",
      children: [
        { title: "Whats On", routerLink: "/whatson" },
        { title: "Featured Events", routerLink: "/events" },
        { title: "Offers", routerLink: "/offers" },
        { title: "Mixes", routerLink: "/mixes" },
        { title: "Videos", routerLink: "/videos" },
        { title: "Opening Times", routerLink: "/opening" },
      ],
    },
  ];

  useEffect(() => {
    let path = window.location.pathname;
    for (var i = 0; i < menuArray.length; i++) {
      console.log(menuArray[i].children.routerLink);
      for (var j = 0; j < menuArray[i].children.length; j++) {
        if (menuArray[i].children[j].routerLink === path) {
          setSelectedTab(i);
          setSelectedChild(j);
          setOpenTab(i);
        }
      }
    }
  }, []);

  function setTab(id) {
    if (openTab === id) {
      setOpenTab();
    } else {
      setOpenTab(id);
    }
  }
  const Map = {
    CalendarIcon: CalendarIcon,
    DrinkIcon: DrinkIcon,
    SettingsIcon: SettingsIcon,
    UserIcon: UserIcon,
    BellIcon: BellIcon,
    GiftIcon: GiftIcon,
    BannerIcon: BannerIcon,
    PencilIcon: PencilIcon,
  };

  return (
    <Container fluid>
      <Row>
        <Accordion
          className={styles["accordion"]}
          activeKey={openTab !== undefined ? openTab.toString() : null}
        >
          {menuArray.map((array, index) => {
            let Tagname = Map[array.TabIcon];
            return (
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={index.toString()}
                  onMouseEnter={() => setMouseOver(index)}
                  onMouseLeave={() => setMouseOver()}
                  onClick={() => setTab(index)}
                >
                  <div>
                    <Tagname
                      className={
                        mouseOver === index
                          ? styles["menu-icon-hover"]
                          : styles["menu-icon"]
                      }
                    />
                  </div>
                  <div
                    className={
                      mouseOver === index
                        ? styles["accordion-hover"]
                        : styles["accordion"]
                    }
                  >
                    {array.TabName}
                  </div>
                  <div>
                    <ArrowIcon
                      className={
                        mouseOver === index
                          ? openTab === index
                            ? styles["arrow-icon-hover-r"]
                            : styles["arrow-icon-hover"]
                          : openTab === index
                          ? styles["arrow-icon-r"]
                          : styles["arrow-icon"]
                      }
                    />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index.toString()}>
                  <Container fluid>
                    {menuArray[index].children.map((arrayChild, indexChild) => {
                      return (
                        <Row
                          onClick={() =>
                            setSelectedChild(indexChild) +
                            setSelectedTab(index) +
                            history.push(arrayChild.routerLink)
                          }
                          onMouseEnter={() => setMouseOverChild(indexChild)}
                          onMouseLeave={() => setMouseOverChild()}
                        >
                          <Card.Body
                            className={
                              selectedTab === index &&
                              selectedChild === indexChild
                                ? styles["child-item-hover"]
                                : mouseOverChild === indexChild
                                ? styles["child-item-hover"]
                                : styles["child-item"]
                            }
                          >
                            {arrayChild.title}
                          </Card.Body>
                        </Row>
                      );
                    })}
                  </Container>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      </Row>
    </Container>
  );
}
export default Menu;

import React from 'react'
import { ReactComponent as AppleIcon } from '../../../images/apple.svg';
import { ReactComponent as EmailIcon } from '../../../images/envelope.svg';
import { ReactComponent as FacebookIcon } from '../../../images/facebook.svg';
import { ReactComponent as AndroidIcon } from '../../../images/android.svg';

function LoginMethod(props){
    return(
        <div>{props.data && props.data === "facebook" && <FacebookIcon/>}
             {props.data && (props.data === "apple" || props.data === "Apple" || props.data === "APPLE") && <AppleIcon/>}
             {props.data && props.data === "email" && <EmailIcon/>}
             {props.data && props.data === "Android" && <AndroidIcon/>}</div>
    )
}
export default LoginMethod
import React,{useState} from 'react'
import {Container, Row, Form, Button} from 'react-bootstrap'
import Header1 from '../Components/Headers/Header1'
import styles from './Notifications.module.css'
import ImageUpload from '../Components/Tables/CustomComponents/ImageInput'
import axiosConfig from  '../axiosNotification'

function Notifications(){

  const [title, setTitle] = useState("")
  const [pushText, setPushText] = useState("")
  const [topImage, setTopImage] = useState("")
  const [body, setBody] = useState("")
  const [middleImage, setMiddleImage] = useState("")
  const [extText, setExtText] = useState("")

  const submit = () =>{
    if(title === ""){ alert("Title cannot be empty");return}
    if(pushText === ""){ alert("Push Title cannot be empty");return}
    if(topImage === ""){ alert("Top Image cannot be empty");return}

    try{
    axiosConfig.post('',{"title":title,"message":body,"headerImage":topImage,"messageExt":extText,"middleImage":middleImage,"pushTitle":pushText})
      .then(response => {
        console.log(response.status)
        alert("Message processing. All messages will be sent within 1 hour.")
        
        })
        .catch(error => {
          console.log(error)
          alert("An error occured.")
        });
      } catch(error){ 
        console.log(error)
        alert("An error occured.")
      }
  }

    return(
        <Container className={styles['main-container']} fluid>
        <Row><Header1 title={"Notifications"}/></Row>

        <Container className={styles['inner-container']} fluid>
        <Form>
  <Form.Group controlId="formBasicText">
    <Form.Label>Push Notification Text</Form.Label>
    <Form.Control type="text" placeholder="BOGOF Today!" onChange={(e) => setPushText(e.target.value)}/>
    <Form.Text className="text-muted">
      This text will appear in the push notification on peoples devices.
    </Form.Text>
  </Form.Group>

  <Form.Group controlId="formBasicText">
    <Form.Label>Inbox Message Top Image</Form.Label>
    <ImageUpload data ='' value_changed={(e) => setTopImage(e.value)} setLoading={() => console.log('loading')}/>
    <Form.Text className="text-muted">
      The header image which will appear in the inbox message.
    </Form.Text>
  </Form.Group>

  <Form.Group controlId="formBasicText">
    <Form.Label>Message Title</Form.Label>
    <Form.Control type="text" onChange={(e) => setTitle(e.target.value)} placeholder="Message Title Text" />
    <Form.Text className="text-muted">
      The title of the inbox message. 
    </Form.Text>
  </Form.Group>

  <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label>Message Body</Form.Label>
    <Form.Control as="textarea" onChange={(e) => setBody(e.target.value)} rows={3} />
  </Form.Group>

  <Form.Group controlId="formBasicText">
    <Form.Label>Inbox Message Middle Image (Optional)</Form.Label>
    <ImageUpload data ='' value_changed={(e) => setMiddleImage(e.value)} setLoading={() => console.log('loading')}/>
    <Form.Text className="text-muted">
      For longer messages an image can be added under the body text.
    </Form.Text>
  </Form.Group>

  <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label>Extention Text (Optional)</Form.Label>
    <Form.Control as="textarea" onChange={(e) => setExtText(e.target.value)} rows={3} />
  </Form.Group>

  <Button variant="primary" onClick={() => submit()} >
    Submit
  </Button>
 
</Form>

        </Container>
        </Container>
    )
}
export default Notifications
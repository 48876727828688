import React, { useEffect, useState } from "react";

function TimestampPreview(props) {
  const [dateString, setDateString] = useState("");

  useEffect(() => {
    var dateFormatted = new Date(props.data);

    setDateString(dateFormatted);
  }, [props]);

  return <div>{dateString.toLocaleString("en-GB", { timeZone: "GB" })}</div>;
}
export default TimestampPreview;

import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import "./ImageInput.css";
import axiosConfig from "../../../axiosConf.js";

function ImageInput(props) {
  const [pictures, setPictures] = useState([]);
  const [defaultImage, setDefaultImage] = useState();

  useEffect(() => {
    if (props.data.current_value) {
      setDefaultImage([props.data.current_value]);
    }
  }, [props.data.current_value]);

  const onDrop = (picture) => {
    console.log(picture[0]);
    props.setLoading(true);
    try {
      let formData = new FormData();
      formData.append("image", picture[0]);

      axiosConfig
        .post("/processimage.php", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response);
          props.setLoading(false);
          props.value_changed({
            value: response.data.imageURL,
            index: props.index,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }

    console.log(pictures);
  };

  return (
    <ImageUploader
      {...props}
      withIcon={false}
      onChange={onDrop}
      imgExtension={[".jpg", ".gif", ".png"]}
      maxFileSize={5242880}
      withPreview={true}
      singleImage={true}
      defaultImages={defaultImage}
      buttonText={"Choose Image"}
    />
  );
}
export default ImageInput;

import React,{useState} from 'react'
import styles from './topmenu.module.css'
import { Container, Row, Accordion, Card, Button, Col } from 'react-bootstrap'
import Menu from '../Components/Menu'
import { ReactComponent as MenuIcon } from '../images/menu.svg';
import Logo from '../images/asm.png';

function TopMenu() {
  const [activeKeyState, setActiveKeyState] = useState(null);
  return (
    <Container className={styles['main-container']} fluid>
      <Accordion className={styles['accordion']} activeKey={activeKeyState !== undefined ? activeKeyState : null}>
        <Card className={styles['no-border']}>
          <Card.Header className={styles['menu-card']}>

            <Container className={styles['header-bar']} fluid>
              <Row><Col className={styles['menu-icon-col']} xs={4}>
                <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={() => setActiveKeyState(activeKeyState === "0" ? null : "0")}>
                  <MenuIcon className={styles['menu-icon']} />
                </Accordion.Toggle>
              </Col><Col className={styles['logo-col']} xs={4}><img src={Logo}/></Col><Col xs={4}></Col>
              </Row>
            </Container>
          </Card.Header>


          <Accordion.Collapse className={styles['menu-card-2']} eventKey="0">
            <Menu didSelect={() => setActiveKeyState(null)} />
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Container>
  )
}
export default TopMenu
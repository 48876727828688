import React from 'react'
import { Container,Row } from 'react-bootstrap'
import Header1 from '../Components/Headers/Header1'
import Table from '../Components/Tables/TableComponent'
import styles from './viewcustomer.module.css'
import UserImage from '../Components/Tables/CustomComponents/UserImage'
import LoginMethod from '../Components/Tables/CustomComponents/LoginMethod'

function ViewCustomers(){

    const components = {
        "UserImage": UserImage,
        "LoginMethod":LoginMethod
    };

    const headers = [{"title":"","value":"thumb","editable":false, "custom_component":"UserImage"},{"title":"Name","value":"name","editable":false},{"title":"Email","value":"email","editable":false},{"title":"Login Method","value":"login_method","editable":false,"custom_component":"LoginMethod"},{"title":"Device","value":"os","editable":false, "custom_component":"LoginMethod"},{"title":"Joined","value":"membersince","editable":false}]

    return(
        <Container className={styles['main-container']} fluid>
            <Row><Header1 title={"View Customers"} /></Row>
            <Row className={styles['table-row']}><Table components={components} headers={headers} getEP={"/customers.php"}/></Row>
        </Container>

    )
}
export default ViewCustomers
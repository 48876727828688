import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import TextArea from "../Components/Tables/CustomComponents/TextAreaInput";
import DayDropDown from "../Components/Tables/CustomComponents/DayDropDown";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";

const components = {
  DayDropDown: DayDropDown,
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  TextArea: TextArea,
};

function OpeningTimes() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Monday",
      value: "monday",
      editable: true,
      place_holder: "9am - 5pm",
      modal_component: "Text",
    },
    {
      title: "Tuesday",
      value: "tuesday",
      editable: true,
      place_holder: "9am - 5pm",
      modal_component: "Text",
    },
    {
      title: "Wednesday",
      value: "wednesday",
      editable: true,
      place_holder: "9am - 5pm",
      modal_component: "Text",
    },
    {
      title: "Thursday",
      value: "thursday",
      editable: true,
      place_holder: "9am - 5pm",
      modal_component: "Text",
    },
    {
      title: "Friday",
      value: "friday",
      editable: true,
      place_holder: "9am - 5pm",
      modal_component: "Text",
    },
    {
      title: "Saturday",
      value: "saturday",
      editable: true,
      place_holder: "9am - 5pm",
      modal_component: "Text",
    },
    {
      title: "Sunday",
      value: "sunday",
      editable: true,
      place_holder: "9am - 5pm",
      modal_component: "Text",
    },
  ];
  const modalData = {
    id: undefined,
    row: [
      {
        title: "Image",
        value: "image",
        editable: true,
        place_holder: "",
        modal_component: "Image",
        custom_component: "ImagePreview",
      },
      {
        title: "Link",
        value: "link",
        editable: true,
        place_holder: "https://www.barfibre.com/",
        modal_component: "Text",
      },
      {
        title: "Aspect Ratio",
        value: "aspect_ratio",
        editable: true,
        place_holder: "0.5",
        modal_component: "Text",
      },
    ],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"Opening Times"}
            buttonText={""}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={"/deleteopening.php"}
            getEP={"/openingtimes.php"}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => setUpdateData(updateData + 1)}
          addEP={"/addopening.php"}
          deleteEP={"/deleteopening.php"}
          editEP={"/updateopening.php"}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default OpeningTimes;

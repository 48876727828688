import userEvent from "@testing-library/user-event";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function DayDropDown(props) {
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    var valueDay = props.data.current_value;
    console.log(props.data.current_value);
    if (props.data.current_value === "Monday") {
      valueDay = 1;
    }
    if (props.data.current_value === "Tuesday") {
      valueDay = 2;
    }
    if (props.data.current_value === "Wednesday") {
      valueDay = 3;
    }
    if (props.data.current_value === "Thursday") {
      valueDay = 4;
    }
    if (props.data.current_value === "Friday") {
      valueDay = 5;
    }
    if (props.data.current_value === "Saturday") {
      valueDay = 6;
    }
    if (props.data.current_value === "Sunday") {
      valueDay = 7;
    }
    console.log(valueDay);
    props.value_changed({ value: valueDay, index: props.index });
    setSelectedValue(valueDay);
  }, []);

  function setSelected(value) {
    console.log(selectedValue + " + " + value);
    if (selectedValue == value) {
      return true;
    } else {
      return false;
    }
  }

  function valueChanged(e) {
    console.log(e);
    props.value_changed({ value: e.target.value, index: props.index });
    setSelectedValue(e.target.value);
  }

  return (
    <Form.Control
      id={selectedValue}
      onChange={(e) => [valueChanged(e)]}
      as="select"
    >
      <option selected={setSelected()} value="">
        Select a Day
      </option>
      <option selected={setSelected(1)} value="1">
        Monday
      </option>
      <option selected={setSelected(2)} value="2">
        Tuesday
      </option>
      <option selected={setSelected(3)} value="3">
        Wednesday
      </option>
      <option selected={setSelected(4)} value="4">
        Thursday
      </option>
      <option selected={setSelected(5)} value="5">
        Friday
      </option>
      <option selected={setSelected(6)} value="6">
        Saturday
      </option>
      <option selected={setSelected(7)} value="7">
        Sunday
      </option>
    </Form.Control>
  );
}
export default DayDropDown;

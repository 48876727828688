import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import styles from './header1.module.css'



function Header1(props){
    return(
        <Container className={styles['container']} fluid>
            <Row className={styles['overview-row']}>OVERVIEW</Row>
            <Row className={styles['title-row']}><Col>{props.title}</Col>{props.buttonText && <Col><Button onClick={() => props.showModal(props.modalData)} className={[styles['btn'],styles['lift']]}>{props.buttonText}</Button></Col>}</Row>
        </Container>
    )
}
export default Header1
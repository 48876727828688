import React from 'react'
import {Form} from 'react-bootstrap'

function TextInput(props){

    function valueChanged(e){
        props.value_changed({"value":e.target.value, "index": props.index})
    }
    

    return(
        <Form.Control type="text" onChange={(e) => valueChanged(e)} placeholder={props.data.place_holder} value={props.data.current_value}/>
    )
}
export default TextInput
import React, { useState } from "react";
import { Container, Row, Dropdown, DropdownButton } from "react-bootstrap";
import Header1 from "../Components/Headers/Header1";
import Table from "../Components/Tables/TableComponent";
import styles from "./prizes.module.css";
import GenericModal from "../Components/Modals/GenericModal";
import TextInput from "../Components/Tables/CustomComponents/TextInput";
import TextArea from "../Components/Tables/CustomComponents/TextAreaInput";
import DayDropDown from "../Components/Tables/CustomComponents/DayDropDown";
import ImageInput from "../Components/Tables/CustomComponents/ImageInput";
import ImagePreview from "../Components/Tables/CustomComponents/ImagePreview";

const components = {
  DayDropDown: DayDropDown,
  Text: TextInput,
  Image: ImageInput,
  ImagePreview: ImagePreview,
  TextArea: TextArea,
};

function WhatsOn() {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState(0);

  const headers = [
    {
      title: "Day",
      value: "day",
      editable: true,
      place_holder: "",
      modal_component: "DayDropDown",
    },
    {
      title: "Title",
      value: "title_new",
      editable: true,
      place_holder: "Bogof",
      modal_component: "Text",
    },
    {
      title: "Subtitle",
      value: "subtitle",
      editable: true,
      place_holder: "Subtitle",
      modal_component: "Text",
    },
    {
      title: "Image",
      value: "image",
      editable: true,
      modal_component: "Image",
      custom_component: "ImagePreview",
    },
    {
      title: "Share",
      value: "share",
      editable: true,
      place_holder: "https://www.barfibre.com/",
      modal_component: "Text",
    },
    {
      title: "Tickets",
      value: "tickets",
      editable: true,
      place_holder: "https://www.barfibre.com/",
      modal_component: "Text",
    },
  ];
  const modalData = {
    id: undefined,
    row: [
      {
        title: "Day",
        value: "day",
        editable: true,
        place_holder: "",
        modal_component: "DayDropDown",
      },
      {
        title: "Title",
        place_holder: "Bogof",
        required: true,
        modal_component: "Text",
      },
      {
        title: "Subtitle",
        place_holder: "Subtitle",
        required: true,
        modal_component: "Text",
      },
      { title: "Image", required: true, modal_component: "Image" },
      {
        title: "Tickets",
        place_holder: "tickets",
        required: true,
        modal_component: "Text",
      },
      {
        title: "Share",
        place_holder: "share",
        required: true,
        modal_component: "Text",
      },
    ],
  };
  return (
    <>
      <Container className={styles["main-container"]} fluid>
        <Row>
          <Header1
            title={"Whats On"}
            buttonText={"Add Whats On"}
            showModal={(e) => setShowModal(modalData)}
          />
        </Row>
        <Row className={styles["table-row"]}>
          <Table
            components={components}
            headers={headers}
            onUpdate={updateData}
            deleteEP={"/deletewhatson.php"}
            getEP={"/whatson.php"}
            canSelectRow={true}
            showModal={(e) => setShowModal(e)}
          />
        </Row>
      </Container>

      {showModal != false && (
        <GenericModal
          components={components}
          modalData={modalData}
          reload={() => setUpdateData(updateData + 1)}
          addEP={"/addwhatson.php"}
          deleteEP={"/deletewhatson.php"}
          editEP={"/updatewhatson.php"}
          show={true}
          data={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
}
export default WhatsOn;
